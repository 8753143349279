body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@mixin tablet-or-smaller {
  @media (max-width: 900px) {
    @content;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(236, 19, 19) #000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  background: #000000;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(236, 19, 19);
  border-radius: 10px;
  border: 3px solid #000000;
}
