@import "../../index.scss";

.Header {
  margin-top: 1rem;
}

.textSection {
  margin-left: 3rem;
  margin-right: 3rem;
  @include tablet-or-smaller {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.pictureContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.smallPicture {
  height: 241px;
}

.largePicture {
  height: 483px;
}
