.notes {
  margin-top: 1rem;
}

.noVertPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}

.videosContainer {
  margin-bottom: 1rem;
}

div.backgroundHighlight {
  background-color: #630013a8;
}

.entryHasVideo {
  background: rgb(236, 19, 19);
  background: radial-gradient(circle, rgba(236, 19, 19, 1) 0%, rgba(0, 0, 0, 1) 100%);
}
.cardBackground {
  cursor: pointer;
}

a {
  color: lightgreen;
  font-style: italic;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
